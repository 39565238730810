@import url('https://fonts.googleapis.com/css?family=Hanken+Grotesk');

.Layout-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-content: center;
    align-items: center;
    gap: 50px;
    background-color: #094C69;
    color: #D9D9D9;
    background-size:cover;
    font-family: Hanken Grotesk;
}