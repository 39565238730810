@import url('https://fonts.googleapis.com/css?family=Righteous');

.frontPage-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  text-align: center;
  gap: 10px;
}
 

.frontPage-container .title {
  font-size: 2rem;
  font-weight: bold;
}

.frontPage-container .text {
  padding: 10px;
  font-size: 17px;
}

@media (min-width: 1000px) {
  .frontPage-container {
    display: flex;
    flex-direction: row;
    width: 60%;
    justify-content: center;
    text-align: center;
  }

  .frontPage-container .title-and-text {
    display: flex;
    flex-direction: column;
    padding: 100px;
    width: 80%;
    align-items: center;
    gap: 40px
  }

  .title-and-text .title {
    font-size: 52px;
    font-weight: 400;
  }

  .frontPage-container .text {
    padding: 10px;
    width: 50%;
    font-size: 20px;
  }
}
