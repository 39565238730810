@import url('https://fonts.googleapis.com/css?family=Righteous');

.header {
  top: 0;
  position: sticky;
  width: 100%;
  z-index: 50;
  background-color: #094C69;
  color: #D9D9D9;
  display: flex;
  justify-content: space-around;
  height: 70px;
  opacity: 0.9;
  align-items: center;
}

.header .logo {
  height: 75px;
  width: 80px;
  display: flex;
  justify-content: center;
}

.logo img{
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.header .buttons {
  display: flex;
  text-align: center;
  gap: 15px;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.buttons .button {
  font-weight: bold;
  font-size: 1rem;
  border-radius: 8px;
  cursor: pointer;
  height: 70%;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 10px;
}

.buttons .button:hover {
  background-color: #3a3d41;
}

@media (min-width: 720px) {
  .header {
    justify-content: space-between;
  }

  .header .logo {
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
  }
  
  .logo img{
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  

  .header .buttons {
    margin-right: 20px;
    gap: 20px;
  }
}
