.services-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  gap: 20px;
}

.services-container .title {
  font-size: 2rem;
  font-weight: bold;
  width: 100%;
  display: flex;
  text-align: center;
}

.services-container .services {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.services .service-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  margin-top: 30px;
}

.service-box .service-logo {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service-logo img {
  width: 160px;
  height: 150px;
  object-fit: fill;
}

.service-box .service-title {
  font-size: 30px;
  font-weight: bold;
}

.service-box .service-text {
  font-size: 20px;
}

@media (min-width: 1000px) {
  .services-container {
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .services-container .title {
    width: 40%;
    font-size: 52px;
    font-weight: 400;
  }

  .services-container .services {
    justify-content: space-around;
    width: 80%;
    flex-direction: column;
    gap: 100px;
  }

  .services .service-horizontal{
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  .services .service-box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    width:40%;
    align-items: center;
  }

  .service-box .service-logo {
    width: 200px;
    height: 180px;
  }

  .service-logo img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .service-box .service-title {
    font-size: 2rem;
    font-weight: bold;
  }
}
